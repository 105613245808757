import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import {hydrate, render, ReactDOM} from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import smoothscroll from 'smoothscroll-polyfill';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

smoothscroll.polyfill();



if (rootElement.hasChildNodes()) {
  hydrate(<BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>, rootElement);
} else {
  render(<BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>, rootElement);
}


registerServiceWorker();

