import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import  Home   from './components/Home';
import { Contact } from './components/Contact';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsConditions } from './components/TermsConditions';
import { AboutUs } from './components/AboutUs';
import { Logo } from './components/Logo';

import './custom.css'


export default function App()  {
    //static displayName = App.name;


    const [isLoading, setIsLoading] = useState(true);
    const [isIE, setIsIE] = useState(false);

    useEffect(() => {

        // Code to check if browser is IE or Edge
        // Edge 20+
        var isEdge = window.navigator.userAgent.indexOf('Edge') !== -1
        // Internet Explorer 6-11
        var isIEvar = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge

        if (isIEvar) {
            setIsIE(true);
    
        }



        //let key = '6Lf_HcQUAAAAAPGyMOGg28GdAioqEEwu67Dw-6Ar';
        //loadReCaptcha(key);
        setIsLoading(false);




    }, []);


    return (


        <div>
            {isIE ?

                <div className="text-center" style={{ margin: '50px', height: '100%', backgroundColor: 'gray', borderRadius: '10px', paddingTop: '30px', paddingBottom: '30px' }}>
                    <Logo />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div style={{ color: "white" }}>
                    <h1>Oh no, Internet Explorer!</h1>
                    <br />
                    <p>It appears you are visiting our website using Internet Explorer, please revisit using an alternative web browser such as Edge, Chrome, Safari or Firefox for an optimised secure modern web experience</p>
                        <p>Here is the link</p>
                        <p>https://www.bermani.co.uk</p>

                    </div>
                    <br />
                    <br />
                </div>
                : 
            

                isLoading  ?

                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :



                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route path='/AboutUs' component={AboutUs} />
                    <Route path='/Contact' component={Contact} />
                    <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
                    <Route path='/TermsConditions' component={TermsConditions} />
                </Layout>
            }
        </div>

    )

}

