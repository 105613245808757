import React from 'react';
import { Map } from './GoogleMap';
import { HashLink } from 'react-router-hash-link';

export  function AboutUs(props) {



  
        // Code to check if browser is IE or Edge
        // Edge 20+
        var isEdge = window.navigator.userAgent.indexOf('Edge') !== -1
        // Internet Explorer 6-11
        var isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge
        var imgURL = ''

        if (isEdge || isIE) {
            imgURL = './imgs/union.png';
        }
        else {
            imgURL = './imgs/union.svg';
        }

    const scrollOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }



        return (
            <div id="aboutUs">
                <div className="container">
                    <div id='r1' className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <h1 id="mainmessage">Revolutionising Clinical Governance Technology one pillar at a time</h1>
                        
                        </div>
                        <div id="mainimgdiv" className="col-xs-12 col-xl-6 col-lg-6">
                            <img id="mainimg" alt="cgjigsaw" src={"../imgs/cgjigsaw.svg"} />
                        </div>
                    </div>
                 {/*</div>


                <div className="container-fluid">}*/}
                    <div className="row section1 align-items-center" id="r2">
                        
                            

                                <div className="col-1"></div>
                                <div className="col-10">
                                    <p className="rowheading">Our mission</p>
                                    <p className="defaultText">Bermani's mission is to empower every healthcare professional and organisation to deliver outstanding patient care
                                    through innovative clinical governance technology </p>
                                </div>
                                <div className="col-1"></div>

                          
                        

                    </div>

                    <div id='r3' className="row">
                        <div className="col-md-6 order-12 order-sm-last order-md-first order-lg-first order-xl-first" >
                            <img id='bermaniheartnhs' src='./imgs/bermaniheartnhs.svg' alt='bermani heart NHS' />
                        </div>
                        <div className="col-md-6">
                            <p className="rowheading">A trusted partner</p>
                            <p className="defaultText"> We work in partnership with the NHS to develop optimised clinical governance solutions that offer 
                                value unmatched by other solution vendors.
                                </p>
                    
                        </div>
                    </div>

                    <div id='r4' className="row">
                        <div className="col-md-6">
                            <p className="rowheading">The bermani difference</p>
                            <p className="defaultText">We are clinicians, we are governance experts and technologists. We put patients and staff
                                at the heart of everything we do and we leverage state of the art technology to push the boundaries of what is possible.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img id='union' src={imgURL} alt='bermani union' />
                        </div>
                    </div>
{/* 
                    <div id='r5' className="row">



                        <div className="col-md-6 order-12 order-sm-last order-md-first order-lg-first order-xl-first" style={{minHeight: '150px'}}>
                            <Map id="googlemap" />


                        </div>
                        <div className="col-md-6">
                            <p className="rowheading">Made in Brum!</p>
                            <p className="defaultText"> We pride ourselves in being a local independant startup with a global ambition, we are located in the heart of Birmingham at Alpha Tower.
                            </p>
                            <HashLink scroll={el => scrollOffset(el)} to="/Contact/#">
                                <button className="btn btn-secondary" tabIndex="3">Get in touch</button>
                            </HashLink>
                            <p>  </p>
                        </div>
                    </div>
*/}
                </div>


            </div>

        );
    }

