import React, {  useState, useEffect } from 'react';

import { Input } from 'reactstrap';

import Switch from "react-switch";


export default function PriceCalculator() {
    const [count, setCount] = useState(8000);
    const [valid, setValid] = useState(true);
    const [NHSDiscount, setNHSDiscount] = useState(true);
    const [previewDiscount, setPreviewDiscount] = useState(true);
    const [costPerEmployee, setCostPerEmployee] = useState(0);
    const [savings, setSavings] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    

    const handleCountChange = (v) => {
        if (!Number.isInteger(v)) {
            setValid(false);
        }

        if (v >= 250 && v <= 50000) {
            setValid(true);
        } else {
            setValid(false);
        }


        if (v > 50000) {
            setCount(50000);
        } else {
            setCount(v);
        }

 
    };


    const handlePriceChange = () => {


        //TODO Add NHS Discount

        let ga = 0;
        let tc = 0;
        let s = 0;
        let pe = 0;

        if (NHSDiscount) {
            if (count >= 250 && count <= 8000) {
                ga = 1939.7 * Math.log(count) - 1432.8;
                tc = 912.82 * Math.log(count) + 1796.3;
                s = ga - tc;
                pe = tc / count;
            } else {
                ga = count * 2;
                tc = count * 1.25;
                s = ga - tc;
                pe = 1.25;
            }
        } else {
            if (count >= 250 && count <= 8000) {
                ga = 2510.3 * Math.log(count) - 2560.1;
                tc = 1939.7 * Math.log(count) - 1432.8;
                s = ga - tc;
                pe = tc / count;
            } else {
                ga = count * 2.5;
                tc = count * 2;
                s = ga - tc;
                pe = 2;
            }
        }
       

    

        setCostPerEmployee(Math.round(pe*100)/100);
        setSavings(Math.round(s));
        setTotalCost(Math.round(tc));
    }



    useEffect(() => {
        
        if (valid) {
            handlePriceChange();
        }



    }, [count, valid, NHSDiscount]);


    return (
        <div>
            <p className="text-left keyText">How much does it cost to empower	&nbsp;
                <Input
                    className={ (valid)? "text-center" : "text-center is-invalid"}
                    type="number"
                    value={count}
                    min="250"
                    max="50000"
                    steps="1"
                    onChange={(v) => handleCountChange(v.target.value)}
                    style={{ display: "inline", whiteSpace: "nowrap", width: "150px", fontWeight: "bolder", fontSize:"large" }}
                    placeholder="Insert Number"
                    required

                />
                 	&nbsp; employees with bermani?
            </p>
            <br/>
            <input
                type="range"
                min="250"
                max="50000"
                step="1"
                value={count}
                onChange={(v) => handleCountChange(v.target.value)}

            />
            <br />
            {!valid && <div style={{ color: "red" }}> Please provide a number between 250 and 50000 </div>}

            <div className="container">

            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-6 justify-content-between p-1">
                    <div className="text-right defaultText">
                            NHS discount
                    </div>
                </div>

                <div className="col-md-2 text-right p-1">
                        <Switch className="align-middle" onChange={(c) => { setNHSDiscount(c) }} checked={NHSDiscount} onColor="#1dbf43" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-6 justify-content-between p-1">
                    <div className="text-right defaultText">
                        Early access discount
                    </div>
                </div>
                <div className="col-md-2 text-right p-1">
                    <Switch className="align-middle" onChange={(c) => { setPreviewDiscount(c) }} checked={previewDiscount} onColor="#1dbf43" disabled />
                </div>
            </div>
          


            <br/>
            
            <div className="row">
                <div className="col-md-4 ">
                </div>
                <div className="col-md-6 justify-content-between p-1">
                    <div className="text-right">
                        Cost per employee per year:
                    </div>
                </div>

                <div className="col-md-2 p-1">
                    <div className="text-right">
                        &pound;{costPerEmployee}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                </div>

                <div className="col-md-6 justify-content-between p-1">
                    <div className="text-right p-1">
                        Savings vs general availability pricing:
                    </div>
                </div>

                <div className="col-md-2 p-1">
                    <div className="text-right">
                        &pound;{savings}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                </div>

                <div className="col-md-6 justify-content-between p-1">
                    <div className="text-right keyText" style={{ marginTop: "10px" }}>
                        Total annual cost (exc VAT):
                    </div>
                </div>

                <div className="col-md-2 p-1">
                    <div className="text-right keyText" style={{ color: "#1dbf43", marginTop: "10px"}}>
                        &pound;{totalCost}
                    </div>
                </div>
            </div>
                
            </div>
           
            
        </div>

    );
}