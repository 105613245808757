import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Footer.css';

export class Footer extends Component {

    render() {

        return (
            <div>
                <footer>
                    <Container>
                        <div className="container-fluid">
                            <div className="row ">
                                <div className="col-4">
                                    <img alt="bermani" src={"../imgs/icon-white.svg"} style={{ height: 30, width: 100 }} />
                                </div>
                                <div className="col-4">

                                </div>
                                <div className="col-4">
                                    <div className="social">
                                        <a href="https://www.facebook.com/BermaniUK/" >
                                            <img alt="facebook" src={"../imgs/f_logo.png"} style={{ height: 25, width: 25 }} /> 
                                        </a>
                                    <span>      </span>
                                        <a href="https://twitter.com/BermaniUK" >

                                            <img alt="twitter" src={"../imgs/t_logo.png"} style={{ height: 25, width: 25 }} /> 
                                          </a>
                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-4">

                                </div>
                                <div id="copyright" className="col-4">
                                    Copyright {new Date().getFullYear().toFixed()} Bermani Ltd. All rights reserved.
                                    <br />
                                    
                                    <Link to="/TermsConditions"> Terms & Conditions </Link>
                                    <span>  -  </span>
                                    <Link to="/PrivacyPolicy"> Privacy Policy</Link>

                                </div>
                                <div className="col-4">

                                </div>
                            </div>



                        </div>

                    </Container>
                </footer>
            </div>
        );
    }
}
