import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import CookieConsent from "react-cookie-consent";

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu />
        <div >
          {this.props.children}
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Agree"
          cookieName="ConsentCookie"
          style={{ background: "#2B373B", opacity: "0.9" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses necessary cookies to enhance the user experience. Necessary cookies enable core functionality such as security, network management, analytics and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.{" "}
    
        </CookieConsent>
        <Footer />
      </div>
    );
  }
}
