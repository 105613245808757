import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoArrowDown } from "react-icons/io5";

export default function Features(props) {

    


    const featuresData = [
        { key: "featureData1", category: 1, featureLogo:"./imgs/GuidedRegistration.svg",                                                                featureTitle: "Guided Registration"           },
        { key: "featureData2",  category:1, featureLogo:"./imgs/ClinicalLeadership.svg",                                                                featureTitle: "Clinical Leadership"           },
        { key: "featureData3",  category:1, featureLogo:"./imgs/AutomatedGovernance.svg",                                                                featureTitle: "Automated Projects' Governance"},
        { key: "featureData4",  category:1, featureLogo:"./imgs/AcceleratedCycle.svg",                                                                featureTitle: "Accelerated Clinical Audit Cycle" },
        { key: "featureData5",  category:1, featureLogo:"./imgs/EmbeddedQI.svg",                                                                featureTitle: "Embedded Quality Improvement"                 },
        { key: "featureData6",  category:1, featureLogo:"./imgs/EmbeddedAI.svg",                                                                featureTitle: "Embedded Appreciative Inquiry"         },
        { key: "featureData7",  category:1, featureLogo:"./imgs/EmbeddedPPI.svg",                                                                featureTitle: "Embedded Patient and Public Involvement" },
        { key: "featureData8",  category:1, featureLogo:"./imgs/AuditsBrowser.svg",                                                                featureTitle: "Audits Browser"     },
        { key: "featureData9",  category:1, featureLogo:"./imgs/NICEIntegration.svg",                                                                featureTitle: "NICE Integration"        },
        { key: "featureData10", category:1, featureLogo:"./imgs/1ClickDCT.svg",                                                                featureTitle: "1-Click Data Collection Tools"},
        { key: "featureData11", category:1, featureLogo:"./imgs/1ClickReport.svg",                                                                featureTitle: "1-Click Reports" },

        { key: "featureData38", category:2, featureLogo:"./imgs/GuidedRegistration.svg",   featureTitle: "Guided Registration" },
        { key: "featureData39", category:2, featureLogo:"./imgs/ClinicalLeadership.svg",   featureTitle: "Clinical Leadership" },
        { key: "featureData40",  category:2, featureLogo:"./imgs/AutomatedGovernance.svg", featureTitle: "Automated Projects' Governance" },
        { key: "featureData41",  category:2, featureLogo:"./imgs/DriverDiagrams.svg", featureTitle: "Driver Diagrams" },
        { key: "featureData42",  category:2, featureLogo:"./imgs/PDSAs.svg", featureTitle: "PDSA Cycles" },
        { key: "featureData43",  category:2, featureLogo:"./imgs/Measures.svg", featureTitle: "Automated Measures" },
        { key: "featureData44",  category:2, featureLogo:"./imgs/SPC.svg", featureTitle: "Dynamic SPC Charts" },
        { key: "featureData45",  category:2, featureLogo:"./imgs/AuditsBrowser.svg",        featureTitle: "QIs Browser"     },
        { key: "featureData46",  category:2, featureLogo:"./imgs/CAIntegration.svg",        featureTitle: "Clinical Audit Integration"     },

        { key: "featureData12", category:3, featureLogo:"./imgs/CloudFirst.svg",                                                                featureTitle: "Cloud First"                               },
        { key: "featureData13", category:3, featureLogo:"./imgs/HighlyConfigurable.svg",                                                                featureTitle: "Highly Configurable"                               },
        { key: "featureData14", category:3, featureLogo:"./imgs/Avatars.svg",                                                                featureTitle: "Avatars"                               },
        { key: "featureData15", category:3, featureLogo:"./imgs/ProfileSelfService.svg",                                                                featureTitle: "Profile Self-Service"                               },
        { key: "featureData16", category:3, featureLogo:"./imgs/Teams.svg",                                                                featureTitle: "Teams"                               },
        { key: "featureData17", category:3, featureLogo:"./imgs/Messaging.svg",                                                                featureTitle: "Messaging"                               },
        { key: "featureData18", category:3, featureLogo:"./imgs/Comments.svg",                                                                featureTitle: "Comments"                               },
        { key: "featureData19", category:3, featureLogo:"./imgs/Notifications.svg",                                                                featureTitle: "Intelligent Notifications"                               },
        { key: "featureData20", category:3, featureLogo:"./imgs/Actions.svg",                                                                featureTitle: "Smarter SMART Actions"                               },
        { key: "featureData21", category:3, featureLogo:"./imgs/Reminders.svg",                                                                featureTitle: "Reminders"                               },
        { key: "featureData22", category:3, featureLogo:"./imgs/AutoSave.svg",                                                                featureTitle: "Auto-Save"                               },
        { key: "featureData23", category:3, featureLogo:"./imgs/Workflows.svg",                                                                featureTitle: "Workflows"                               },
        { key: "featureData24", category:3, featureLogo:"./imgs/1ClickApproval.svg",                                                                featureTitle: "1-Click Approval"                               },
        { key: "featureData25", category:3, featureLogo:"./imgs/ArtificialIntelligence.svg",                                                                featureTitle: "Artificial Intelligence"                               },
        { key: "featureData26", category:3, featureLogo:"./imgs/MachineLearning.svg",                                                                featureTitle: "Machine Learning"                               },
        { key: "featureData27a", category:3, featureLogo:"./imgs/Questionnaires.svg",                                                                featureTitle: "Questionnaires"                               },
        { key: "featureData27b", category:3, featureLogo:"./imgs/Analytics.svg",                                                                featureTitle: "Analytics"                               },
        { key: "featureData28", category:3, featureLogo:"./imgs/QuickExport.svg",                                                                featureTitle: "Quick Export"                               },
        { key: "featureData29", category:3, featureLogo:"./imgs/DocumentStorage.svg",                                                                featureTitle: "Document Storage"                               },
                                                         
        { key: "featureData30", category:4, featureLogo:"./imgs/SingleSignOn.svg",                                                               featureTitle: "Single Sign On" },
        { key: "featureData31", category:4, featureLogo:"./imgs/UserManagement.svg",                                                                featureTitle: "User Management"                               },
        { key: "featureData32", category:4, featureLogo:"./imgs/EnterpriseSecurity.svg",                                                                featureTitle: "Enterprise-grade Security"                               },
        { key: "featureData33", category:4, featureLogo:"./imgs/IsolatedTenantDb.svg",                                                                featureTitle: "Isolated Tenant Database"                               },
        { key: "featureData34", category:4, featureLogo:"./imgs/IsolatedTenantStorage.svg",                                                                featureTitle: "Isolated Tenant Storage"                               },
        { key: "featureData35", category:4, featureLogo:"./imgs/Multitenant.svg",                                                                featureTitle: "Multi-tenant Ready"                               },
        { key: "featureData36", category:4, featureLogo:"./imgs/DWIntegration.svg",                                                                featureTitle: "Data Warehouse Integration"                               },
        { key: "featureData37", category:4, featureLogo:"./imgs/AuditLogs.svg",                                                                featureTitle: "Audit Logs"                               },


    ];






    //Slider data
    const [navf1, setNav1] = useState();
    const [navf2, setNav2] = useState();
    //const [sliderfIndex, setSliderfIndex] = useState(0);
    //const sliderf1 = useRef();
    //const sliderf2 = useRef();



    const slider1Content = [
        { key: "slider1fcontent1",title: "Clinical Audit Features" },
        { key: "slider1fcontent2",title: "Quality Improvement Features" },
        { key: "slider1fcontent3",title: "Platform Features" },
        { key: "slider1fcontent4",title: "Enterprise Features" }

    ];

    const slider2Content = [
        { key: "slider2fcontent1", categoryName:"Clinical Audit Features", category: 1 , prev:"Enterprise Features", next:"Quality Improvement Features"},
        { key: "slider2fcontent2", categoryName:"Quality Improvement Features", category: 2 , prev:"Clinical Audit Features", next:"Platform Features"},
        { key: "slider2fcontent3", categoryName:"Platform Features", category: 3 , prev:"Quality Improvement Features", next:"Enterprise Features"},
        { key: "slider2fcontent4", categoryName:"Enterprise Features", category: 4 , prev:"Platform Features", next:"Clinical Audit Features"}

       
    ];


    const slider1Settings = {
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        vertical: false,
        arrows: false,
        focusOnSelect: true,
        className: "featureSelectorSlider",
        adaptiveHeight: false,

    };

    const slider2Settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        fade: true,
        arrows: true,
        autoplay: false,
        easing: 'ease-in',
        adaptiveHeight: true,
    
    };



    useEffect(() => {



    }, [props.isMobile]);



    return (
        <div className="container">

            {props.isMobile && 
                 <p className="text-center defaultText">Swipe right to view more features <IoArrowDown /></p>

            }
            {!props.isMobile && 
            <div className="row justify-content-center ">
                <Slider
                    {...slider1Settings}
                    asNavFor={navf2}
                    ref={sliderf1 => setNav1(sliderf1)}
                >
                    {slider1Content.map((item, si) => (

    
                
                            <div key={item.key} className="featureHeading clickable">{item.title}</div>
                            
                  


                    ))}
                </Slider>
            </div>
            }
            <div>
                <br/>
                <Slider
                    {...slider2Settings}
                    asNavFor={navf1}
                    ref={sliderf2 => setNav2(sliderf2)}
                 
                >
                    {slider2Content.map((feature, i) => (
                        <div key={feature.key} className={`container cat` + + feature.category + `Background`} >
                            
                            <div className="row justify-content-center" >
                                <h2 style={{color:'white', padding:'25px', textAlign:'center'}}>{feature.categoryName}</h2>
                            </div>
                            <div className="row justify-content-center" >
                                {featuresData.filter(x => x.category === feature.category).map((feature, i) => (

                                    <div key={feature.key} className="p-2" >
                                        <div className="featureBoxMini ">
                                            <img src={feature.featureLogo} className="row featureLogoMini" />
                                            <div className="row featureTitleMini">{feature.featureTitle}</div>
                                        </div>
                                    </div>
                                ))}

                                
                            </div>
                            <br />
                            <div className="row justify-content-between ">
                                
                      
                            </div>
                        </div>
                    ))}
               
                </Slider>
            </div>


        </div>
    );
}

