import React, { Component } from 'react';

export class TermsConditions extends Component {
    static displayName = TermsConditions.name;


    render() {
        return (
            <div id="TermsConditions" className="container"  >


                <h1>Terms and Conditions</h1>
                <p><strong>Bermani Terms of Service:</strong> 06/12/2019</p>
                <p>&nbsp;</p>
                <p>By using the Bermani.co.uk (&ldquo;Service&rdquo;) and all services of Bermani Limited (&ldquo;Bermani&rdquo;), you are agreeing to be bound by the following terms and conditions (&ldquo;Terms of Service&rdquo;).</p>
                <p>Bermani reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms of Service at any time at: <a href="https://www.bermani.co.uk/TermsConditions">https://www.bermani.co.uk/TermsConditions</a> &nbsp;.</p>
                <p>Violation of any of the terms below will result in the termination of your Account. While Bermani prohibits such conduct and Content on the Service, you understand and agree that Bermani cannot be responsible for the Content posted on the Service and you nonetheless may be exposed to such materials. You agree to use the Service at your own risk.</p>
                <p><strong>Account Terms</strong></p>
                <p>You must be 18 years or older to use this Service. You must be a human. Accounts registered by &ldquo;bots&rdquo; or other automated methods are not permitted. You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process.</p>
                <p>Your login may only be used by one person &ndash; a single login shared by multiple people is not permitted. You may create separate logins for as many people as you'd like.</p>
                <p>You are responsible for maintaining the security of your account and password. Bermani cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. You are responsible for all Content posted and activity that occurs under your account (even when Content is posted by others who have accounts under your account).</p>
                <p>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
     



            </div>
        );
    }
}
