import React, { Component } from 'react';

export class Logo extends Component {


  render() {
    return (
      <div>
            <img alt="bermani" src={"../imgs/icon-logo-white.svg"} style={{ height: 60, width: 200, fontFamily:'Comfortaa', marginTop:-5}} />
      </div>
    );
  }
}
