import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import './NavMenu.css';
import { Logo } from './Logo';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';


export function NavMenu(props) {





    const [collapsed, setCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);


    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const scrollOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }



    return (

        <Navbar className="navbar-expand-md navbar-toggleable-md ng-white navbar-dark mb-3 ">
            <NavbarBrand>
                <NavHashLink smooth to="/#"  >
                    <Logo />
                </NavHashLink>
            </NavbarBrand>
            <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />


            {collapsed &&
                <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
                    <Nav navbar>
                    <Dropdown nav isOpen={dropdownOpen} toggle={toggle} className="navDropdown">
                        <DropdownToggle className="text-white" nav caret>
                            The Platform
                        </DropdownToggle>
                        <DropdownMenu>
                        
                                <HashLink className="customNavLink" smooth to="/#" smooth >bermani</HashLink>
                       
                            <DropdownItem divider />
                     
                                <HashLink className="customNavLink" to="/#benefits" scroll={el => scrollOffset(el)} >Benefits</HashLink>
                   
                      
                                <HashLink className="customNavLink" to="/#features" scroll={el => scrollOffset(el)} >Features</HashLink>
                    
                        
                                <HashLink className="customNavLink" to="/#resiliance" scroll={el => scrollOffset(el)} >Resiliance</HashLink>
                         
                      
                                <HashLink className="customNavLink" to="/#promise" scroll={el => scrollOffset(el)} >Our Promise</HashLink>
                         
                        </DropdownMenu>
                    </Dropdown>


                        <NavItem >
                            <HashLink   className="text-white customNavLink" to="/#pricing" scroll={el => scrollOffset(el)}>Pricing</HashLink>
                        </NavItem>

                        <NavItem >
                            <HashLink  className="text-white customNavLink" smooth to="/AboutUs/#">About Us</HashLink >
                        </NavItem>
                        <NavItem >
                        <HashLink className="text-white customNavLink" scroll={el => scrollOffset(el)} to="/Contact/#">Get in Touch</HashLink >
                        </NavItem>

                    </Nav>
                </Collapse>
            }




            {!collapsed &&
                <Nav navbar  id="navBarMobile">
                    
                <HashLink onClick={() => toggleNavbar()}className="text-white customNavMenu" smooth to="/#" >&nbsp;&nbsp;bermani</HashLink>         
                <DropdownItem divider />
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" to="/#benefits" scroll={el => scrollOffset(el)} >&nbsp;&nbsp;Benefits</HashLink>
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" to="/#features" scroll={el => scrollOffset(el)} >&nbsp;&nbsp;Features</HashLink>
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" to="/#resiliance" scroll={el => scrollOffset(el)} >&nbsp;&nbsp;Resiliance</HashLink>
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" to="/#pricing" scroll={el => scrollOffset(el)}>&nbsp;&nbsp;Pricing</HashLink>
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" to="/#promise" scroll={el => scrollOffset(el)}>&nbsp;&nbsp;Our Promise</HashLink>
                <DropdownItem divider />
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" smooth to="/AboutUs/#">&nbsp;&nbsp;About Us    </HashLink>
                <DropdownItem divider />
                <HashLink onClick={() => toggleNavbar()} className="text-white customNavMenu" scroll={el => scrollOffset(el)} to="/Contact/#">&nbsp;&nbsp;Get in Touch</HashLink>
                    
                </Nav>}


        </Navbar>

    );
}

