import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';


const AnyReactComponent = ({ text }) => <div style={{width:'45px'}}><img src='./imgs/icon-transparent.svg' alt=' ' style={{ height: '15px' , weight:'100%'}} /><b>{text}</b> at Alpha Tower</div>;

export class Map extends Component {
    static defaultProps = {
        center: {
            lat: 52.478592, 
            lng: -1.906329
        },
        zoom: 16
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyC4FEhGQppsuEBm-jsz6-0o4qZo7y56MSQ' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={52.478592}
                        lng={-1.906329}
                        text="Bermani Limited"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}






