import React, {  useState, useEffect} from 'react';
import { Container, Button } from 'reactstrap';
import Typewriter from 'typewriter-effect';
import { useMediaQuery } from 'react-responsive'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Emoji from "react-emoji-render";
import PriceCalculator from './PriceCalculator';
import { FcOk } from "react-icons/fc";
import YouTube from 'react-youtube';
import AccelerateChart from './AccelerateChart';
import Features from './Features';
import { IoRocketOutline } from "react-icons/io5";
import { GoTelescope } from "react-icons/go";
import { RiMoneyPoundCircleLine } from "react-icons/ri";

import { IoMdUnlock } from "react-icons/io";



export default function Home() {


    const [bgimgURL, setBgImgURL] = useState('./imgs/mainBackground.svg')

    const isMobile = useMediaQuery({ query: '(max-device-width: 576px)' });



    //Slider data
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [sliderIndex, setSliderIndex] = useState(0);
    //const slider1 = useRef();
    //const slider2 = useRef();



    const slider1Content = [
        { key:"slider1content1", title: "bermani guides you through project registration " },
        { key:"slider1content2", title: "bermani recommends audit standards for you " },
        { key:"slider1content3", title: "bermani designs your data collection tool" },
        { key:"slider1content4", title: "bermani analyses your audit data " },
        { key:"slider1content5", title: "bermani generates your audit recommendations" },
        { key:"slider1content6", title: "bermani helps you with your improvement plan " },
        { key:"slider1content7", title: "bermani tracks your improvement progress" },
        { key:"slider1content8", title: "bermani writes your audit report " },
        { key:"slider1content9", title: "bermani automates audits' governance" },
    ];                      

    const slider2Content = [
        { key: "slider2content1", featureTitle: "Streamlined Project Registration"      ,source:"./imgs/RegistrationExport.svg", featureDescription: "bermani offers a guided stepped approach to project registration that perfectly aligns to your organisation's requirements" },
        { key: "slider2content2", featureTitle: "AI-Driven Standards Engine"            ,source:"./imgs/StandardsExport.svg", featureDescription: "bermani utilises artificial intelligence and machine learning to recommend audit standards (patent pending)" },
        { key: "slider2content3", featureTitle: "1-Click Data Collection Tools"         ,source:"./imgs/DCTExport.svg", featureDescription: "With a click of a button bermani generates data collection tools tailored to your audit project" },
        { key: "slider2content4", featureTitle: "Live Analytics"                        ,source:"./imgs/AnalysisExport.svg", featureDescription: "With bermani, audit results are analysed in real-time as data is captured" },
        { key:"slider2content5", featureTitle: "Dynamically Generated Recommendations"  ,source:"./imgs/RecommendationsExport.svg", featureDescription: "bermani dynamically generates recommendations to reflect the latest audit results" },
        { key:"slider2content6", featureTitle: "QI-Approch to Improvement"              ,source:"./imgs/ImprovementExport.svg", featureDescription: "bermani integrates QI methodology in clinical audit and offers a smarter approach to SMART actions" },
        { key:"slider2content7", featureTitle: "Evidenced Improvement"                  ,source:"./imgs/TrackingExport.svg", featureDescription: "Easily capture and evidence your quality improvement journey with bermani" },
        { key:"slider2content8", featureTitle: "1-Click Reporting"                      ,source:"./imgs/ReportExport.svg", featureDescription: "With a click of a button bermani generates your clinical audit report which you can easily amend to add your personal touch" },
        { key:"slider2content9", featureTitle: "Automated Projects Governance"          ,source:"./imgs/GovernanceExport.svg", featureDescription: "bermani's highly configurable workflow engine automates your clinical audit governance process eleminating wasteful administrative tasks" },
    ];                                                                                

    const slider3Content = [
        { key:"slider3content1", featureLogo:"./imgs/GDPR.svg",                                                        featureTitle: "GDPR & DPA", featureDescription: "Effective policies & procedures in place to ensure GDPR and Data Protection Act compliance" },
        { key:"slider3content2", featureLogo:"./imgs/CyberEssentials.svg",                                                        featureTitle: "Cyber Essentials", featureDescription: "Cyber Essentials Certified" },
        { key:"slider3content2b", featureLogo:"./imgs/CyberEssentialsPlus.svg",                                                        featureTitle: "Cyber Essentials Plus", featureDescription: "Cyber Essentials Plus Certified" },
        { key:"slider3content3", featureLogo:"./imgs/NHSDSP.svg",                                                        featureTitle: "NHS DSP Toolkit & Information Governance", featureDescription: "Compliant with NHS Data Protection Toolkit Requirements (V3)" },
        { key:"slider3content4", featureLogo:"./imgs/ICO.svg",                                                        featureTitle: "ICO", featureDescription: "Registred with the Information Commissioner's Office with a nominated Data Protection Officer" },
        { key:"slider3content5", featureLogo:"./imgs/ResiliantPlatform.svg",                                                        featureTitle: "Resiliant platform", featureDescription: "Hosted on Microsoft Azure with cross regional failover & tested Business Continuity plan" },
        { key:"slider3content6", featureLogo:"./imgs/DevOps.svg",                                                        featureTitle: "DevOps", featureDescription: "Automated DevOps with Continous Integration & Continous Delivery (CI/CD)" },
        { key:"slider3content7", featureLogo:"./imgs/ISO27001.svg",                                                        featureTitle: "ISO 27001", featureDescription: "ISO 27001 Certified Microsoft Azure Cloud" },
        { key:"slider3content8", featureLogo:"./imgs/SOC.svg",                                                        featureTitle: "SOC", featureDescription: "SOC 1,2,3 Certified Microsoft Azure Cloud" },

    ];

    const slider1Settings = {
        dots: false,
        slidesToShow: 9,
        slidesToScroll: 0,
        vertical: true,
        arrows: false,
        focusOnSelect: true,
        pauseOnHover: true,
        afterChange: (index) => { setSliderIndex(index) },
   


    };

    const slider2Settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 10000,
        easing: 'ease-in',
        pauseOnHover: true

    };

    const slider3Settings = {
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: 'linear',
        pauseOnHover: true,
        responsive: [
            { breakpoint: 5000, settings: { speed: 9000, slidesToShow: 4, arrows: false, autoplaySpeed: 0, centerMode: true} },
            { breakpoint: 992, settings: { speed: 9000, slidesToShow: 3, arrows: false, autoplaySpeed: 0, centerMode: true } },
            { breakpoint: 768, settings: { speed: 9000, slidesToShow: 2, arrows: false, autoplaySpeed: 0, centerMode: true } },
            { breakpoint: 576, settings: { speed: 1500, slidesToShow: 1, arrows: true, autoplaySpeed: 6000, centerMode: false, swipeToSlide:true } },
        ]




        
    };



    //What's included
    const whatIsIncluded = [
        { key:"whatIsIncluded1",  description:"Hosting"},
        { key:"whatIsIncluded2",  description:"Configuration"},
        { key:"whatIsIncluded3",  description:"Onboarding"},
        { key:"whatIsIncluded4",  description:"Train the trainer"},
        { key:"whatIsIncluded5",  description:"Video support"},
        { key:"whatIsIncluded6",  description:"Phone support"},
        { key:"whatIsIncluded7",  description:"Email support"},
        { key:"whatIsIncluded8",  description:"Support SLA"},
        { key:"whatIsIncluded9",  description:"Annual subscription"},
        { key:"whatIsIncluded10", description:"System administration"},
        { key:"whatIsIncluded11", description:"Dedicated Customer Success Specialist"},
        { key:"whatIsIncluded12", description:"Future modules at no extra cost"},
    ];


    //Promise data
    const promiseData = [
        { key: "promiseData1", promiseLogo:"./imgs/NoProfit.svg",                                                      promiseTitle: "No profit taking from the NHS"  , promiseDescription: "We promise to always reinvest all revenue generated from the NHS in bermani to improve the platform for everyone" },
        { key: "promiseData2", promiseLogo:"./imgs/Happiness.svg",                                                      promiseTitle: "Your happiness is ours"          , promiseDescription: "We will always strive to provide you with a personalised customer service that exceeds your expectations" },
        { key: "promiseData3", promiseLogo:"./imgs/BetterValue.svg",                                                      promiseTitle: "Even better value over time"     , promiseDescription: "We will not charge you for future modules and new platform functionality, all new functionality will be made available to you as we evolve the platform at no additional license cost" },
      
    ];


    useEffect(() => {




    }, []);





    return (
        <div id="#">
            
            <div 
                style={{ backgroundImage: `url(${bgimgURL})`, backgroundPositionX: 'center', backgroundPositionY: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <Container >
                    <div  className="container" >
                        <div className="row" >
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-white" >
                                    <h1 className="mainmessage">The  <Typewriter
                                        options={{
                                            strings: ['user-friendly', 'affordable', 'intelligent', 'innovative'],
                                            autoStart: true,
                                            loop: true,
                                            delay: 50,
                                            deleteSpeed: 50,
                                            pauseFor: 2500,
                                        }}
                                    /> <br />
                                clinical effectiveness platform <br /> made for the NHS</h1>
                                

                            </div>
                                   
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-white text-left submessage"  >
                                <p >bermani is a web platform designed, developed and supported by people who truly understand your challenges since our team has faced the same challenges in past healthcare roles</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-white text-center"  >
                                <Button  disabled={true} className="CTA CTAMain" href="https://outlook.office365.com/owa/calendar/Bookings@bermani.co.uk/bookings/s/kNutwhKZMk-_M427T8qxbQ2"><strong>Book your free 20 minutes demo</strong></Button>
                            </div>
                        </div>

                   


                        <div id="videoContainer" className="row justify-content-center align-items-center"> 
                            <YouTube
                                containerClassName={"col-xl-8 col-lg-8 col-md-9 col-sm-10 "}
                                videoId="V4z4MxrHkZw"
                                opts={{
                                    playerVars: {
                                        rel: 0,
                                        origin: window.location.origin,
                                        host: 'https://www.youtube.com',
                                    }

                                }}
                            />
                   
                        
                        </div>
                      
                    </div>
                    
                </Container>
            </div>

            <div>

            <Container className="container" >
  

                <div id="benefits" className="row  align-items-center gridRow" >
                        <p className="mainRowHeading"> This is the future of Clinical Audit <GoTelescope /></p>
                 </div>
                <div className="row">
                    <div className="col-md-6">
                 
                            <Slider
                                {...slider1Settings}
                                asNavFor={nav2}
                                ref={slider1 => setNav1(slider1)}
                                
                            >
                                {slider1Content.map((item, si) => (
                                    (si === sliderIndex) ?
                                    <div key={item.key} className="benefitItem selectedItem clickable">{item.title}</div>
                                    :
                                    <div key={item.key} className="benefitItem clickable">{item.title}</div>
                                
                                
                            ))}
                        </Slider>
                   
                    </div>
                    <div className="col-md-6 featureScroller">
                        <Slider {...slider2Settings}
                            asNavFor={nav1}
                            ref={slider2 => setNav2(slider2)}
                        >
                            {slider2Content.map((feature, i) => (
                                <div key={feature.key} className="container ">
                                    <div className="container featureBox ">
                                        <img alt="Loading" src={feature.source} className="row featureLogo" />
                                        <div className="row featureTitle"><strong>{feature.featureTitle}</strong></div>
                                        <div className="row featureDescription">{feature.featureDescription}</div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>

                        

                    </div>
           

                <div className="row miniGridRow">
                        <div className="col-xs-12 col-lg-6  order-12 order-sm-last order-md-last order-lg-first order-xl-first chartClass" >
                            <AccelerateChart isMobile={isMobile}/>
                            
                    </div>
                    <div className="col-xs-12 col-lg-6">
                            <p className="mainRowHeading">Accelerate your Clinical Audit cycle  <IoRocketOutline /></p> 
                            <p className="defaultText">bermani is the only clinical effectiveness platform that leverages automation, artificial intelligence and machine learning to accelerate clinical audit (patent pending). </p>
                            <p className="defaultText">Empower your clinicians and quality governance professionals to spend less time on tedious administrative tasks and more time focusing on what's important to them, patient care and quality improvement activities.</p>
                  
                    </div>
                </div>

                <div className="row miniGridRow">
                        <div className="col-xs-12 col-lg-6  order-12 order-sm-last order-md-last order-lg-first order-xl-first chartClass" >
                            <p className="mainRowHeading">Unlock your Quality Improvement potential <IoMdUnlock /></p> 
                            <p className="defaultText">bermani implements an elegant approach to Quality Improvement that guides you on your QI journey; craft engaging Driver Diagrams, test change ideas across multiple Plan, Do, Study, Act cycles and measure the impact of your improvement activities with SPC charts that automagically integrate with your organisation's existing datasets. </p>
                           
                          
                        </div>
                    <div className="col-xs-12 col-lg-6">
              
                          
                            <img src='./imgs/QIMain.svg'  />
                        
                    </div>
                </div>

                <div className="row">
                        <Button disabled={true}  className="CTA CTASupport " href="https://outlook.office365.com/owa/calendar/Bookings@bermani.co.uk/bookings/s/kNutwhKZMk-_M427T8qxbQ2"><strong>Book a demo</strong></Button>
                </div>



                <div id="features" className="row gridRow shadedBackground">
                    <div className="col-md-12">
                         <p className="mainRowHeading text-center">Features & Capabilities</p>
                            <p className="text-center defaultText">Benefit from the great features that come as standard with bermani</p>
                            <Features isMobile={isMobile} />
                    
                    </div>
                </div>

                <div id="resiliance" className="row gridRow">
                        <div className="col-md-12">
                        <p className="mainRowHeading text-center">Resiliant & Compliant</p>
                        <p className="text-center defaultText">Keeping your privacy and data security at the centre of what we do</p>
                            <div className="container " >
                                <Slider {...slider3Settings} className="complianceBox p-2"
                                >
                                    {slider3Content.map((feature, i) => (
                                        <div key={feature.key} className="container  ">
                                            <div className="container ">
                                                <img alt="Loading" src={feature.featureLogo} className="row resiliantLogoMini" />
                                                <div className="row resiliantTitleMini"><strong>{feature.featureTitle}</strong></div>
                                                <div className="row resiliantDescriptionMini">{feature.featureDescription}</div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                          
                        </div>
                    </div>
                </div>


                <div className="row gridRow shadedBackground" id="pricing">
                    <div className="col-md-12">

                            <p className="mainRowHeading text-center">Big on Value,</p>
                                <p className="miniMainRowHeading text-center">small on price <Emoji style={{ fontSize: '20px', marginBottom: '10px' }} text=":pound_banknote:" /></p> 
                        
                        <div className="container">

                                <div className="col-sm-2 col-md-4">
                                    <RiMoneyPoundCircleLine style={{
                                        fontSize: '250px', color: '#1DBF43', 
                                    }}
                                    className="text-center p-0"
                                    />

                          
                                </div>
                                
                                <div className="col-sm-10 col-md-8">
                                    <br />
                                    <PriceCalculator />
                                    <br />
                                    <p className="text-left keyText">What's included</p>
                                    <div className="container">
                                        {whatIsIncluded.map((feature, i) => (
                                            <div key={feature.key} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 p-2" >
                                                <div className="row">
                                                    <div className="col-2 p-0">
                                                        <FcOk />
                                                    </div>
                                                    <div className="col-10 pl-1" >
                                                        <div className="whatIsIncludedDescription">{feature.description}</div>
                                                    </div>  
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>    
                        </div>
                            <div className="row">
                                <Button disabled={true} className="CTA CTASupport " href="https://outlook.office365.com/owa/calendar/Bookings@bermani.co.uk/bookings/s/kNutwhKZMk-_M427T8qxbQ2"><strong>Book a demo</strong></Button>
                            </div>
                    </div>

                </div>


                    <div id="promise" className="row gridRow ">
                        <div className="col-md-12">
                            <p className="mainRowHeading text-center">A promise well kept to our NHS</p>
                           
                            <div className="container">
                                {promiseData.map((promise, i) => (

                                    <div key={promise.key} className="col-xs-12 col-sm-12 col-md-4 p-4" >
                                        <div >
                                            <img alt="Loading" src={promise.promiseLogo} className="row resiliantLogoMini" / >
                                            <div className="row resiliantTitleMini"><strong>{promise.promiseTitle}</strong></div>
                                            <div className="row resiliantDescriptionMini ">{promise.promiseDescription}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>




                    <div className="row gridRow shadedBackground">
                        <div className="col-md-12">
                            <p className="mainRowHeading text-center">Calling all modern-day pioneers</p>
                            <div className="container">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-center peopleHeader p-4 ">Quality Governance Professionals</div>
                                        <ul className="text-left">
                                            <li className="peapleDescription">Spend less time managing audits</li>
                                            <li className="peapleDescription">Maximise your impact at scale</li>
                                            <li className="peapleDescription">Overcome barriers to effective clinical audit</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="text-center peopleHeader p-4">Healthcare Leaders</div>
                                        <ul className="text-left ">
                                            <li className="peapleDescription">Improve quality and achieve savings with bermani</li>
                                            <li className="peapleDescription">Improve your staff experience and patient impact</li>
                                            <li className="peapleDescription">Support an alternative not for profit approach to NHS supply</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-center peopleHeader p-4">Doctors, Nurses, Allied Health Professionals and everyone in between</div>
                                        <ul className="text-left ">
                                            <li className="peapleDescription">Spend more time looking after patients</li>
                                            <li className="peapleDescription">Less admin, more quality improvement</li>
                                            <li className="peapleDescription">Junior doctors, we got you covered!</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="text-center peopleHeader p-4 ">Patients</div>
                                        <br />
                                        <ul className="text-left">
                                            <li className="peapleDescription">Clinicians will have more time to focus on your care and improve the services you receive</li>
                                            <li className="peapleDescription">With bermani, you are part of the team, supporting your clinicians with clinical audit and quality improvement activities</li>
                                            
                                        </ul>
                                    </div>
                                </div>

                                    
                            </div>
                            <div className="row">
                                <Button disabled={true} className="CTA CTASupport" href="https://outlook.office365.com/owa/calendar/Bookings@bermani.co.uk/bookings/s/kNutwhKZMk-_M427T8qxbQ2"><strong>Book a demo</strong></Button>
                            </div>
                        </div>
                    </div>


  





















               
                    </Container>

                </div>
            </div>  
            

    );

}
