import React, { Component } from 'react';

export class PrivacyPolicy extends Component {
    static displayName = PrivacyPolicy.name;


    render() {
        return (
            <div id="PrivacyPolicy" className="container" >


                <h1>Privacy Policy</h1>
                <br />
                <p><strong>Date updated</strong>: 06/12/2019</p>
                <p>&nbsp;</p>
                <p>When you use Bermani (collectively &ldquo;Bermani&rdquo;, &ldquo;we&rdquo; and &ldquo;us&rdquo;) , you&rsquo;re trusting us with your personal data. We offer Services (&ldquo;Services&rdquo;, &ldquo;Sites&rdquo;, or &ldquo;Bermani Services&rdquo;) that enable organisations to manage their clinical governance processes.This privacy policy describes how Bermani is committed to the security and the protection of your and your Users' data in line with legal requirements and best practices.</p>
                <p>We will continue to build and execute on our existing security practices to ensure we take all reasonable measures to secure and protect your data in accordance with all applicable laws, including the EU Data Protection Directive 95/46/EC, the EU General Data Protection Regulation 2016/679, the EU ePrivacy Directive 2002/58/EC as amended by Directive 2009/136/EC, as amended or superseded from time to time, and any national implementing legislation (&ldquo;Data Protection Laws&rdquo;).</p>
                <p><strong>Overview</strong></p>
                <p>Bermani obtains Personal Data about you from various sources to provide our Services and to manage our Sites. &ldquo;You&rdquo; may be a visitor to one of our websites, a user of one or more our Services (&ldquo;User&rdquo; or &ldquo;Organisation&rdquo;) or an employee of a user (&ldquo;Employee&rdquo;). If you are an Employee, Bermani will generally not collect your Personal Data directly from you. Your agreement with the relevant Organisation should explain how the Organisation shares your personal data with Bermani, and if you have questions about this sharing, then you should direct those questions to the Organisation.</p>
                <p>This privacy policy is meant to help you understand what information we collect, why we collect it and how you can update, manage, export and delete your information.</p>
                <p>If at any time you are concerned or have questions about how we might be handling your data, please reach out to our Data Protection Officer at <a style={{ color: '#FF0000'}} href="mailto:support@bermani.co.uk">support@bermani.co.uk</a> .&nbsp;<a href="mailto:haider@bermani.co.uk%0b%0b"><br /> <br /> </a></p>
                <p><strong>Personal data that Bermani collects</strong></p>
                <p><strong>THINGS THAT YOU CREATE OR PROVIDE TO US</strong></p>
                <ul>
                    <li>When you create a Bermani Account, you or your Organisation provide us with personal information that includes your name, personal/business email, job title, business location.</li>
                    <li>When your account is created, you may be provided with a Bermani id and sent an email to set your Bermani password. You can also choose to add or remove a phone number and email address.</li>
                    <li>When you fill in our online form to contact our team, we collect your full name, work email, job title, organisation name and address.<br /> </li>
                </ul>
                <p><strong>INFORMATION THAT WE COLLECT AS YOU USE BERMANI</strong></p>
                <p>Our Sites use cookies and other technologies to function effectively. These technologies record information about your use of our Sites, including:</p>
                <ul>
                    <li><strong>Browser and device data</strong><br /> We use collect information about the browsers and devices that you use to access Bermani services, such as IP address, device type, operating system name and version, device manufacturer and model, and language.</li>
                    <li><strong>Usage data</strong><br /> We collect information about your usage of our services. This includes time spent on the Sites, pages visited, links clicked, language preferences and the pages that led or referred you to our Sites.</li>
                </ul>
                <p><strong>Why Bermani collects data</strong></p>
                <p>We use the information that we collect from all our services for the following purposes:</p>
                <ul>
                    <li><strong>Provide our products and services</strong><br /> We use your information to deliver Bermani Services and facilitate the business relationships we have with our Users, such as enabling audit registration and sending email notifications.</li>
                    <li><strong>Maintain and improve Bermani</strong><br /> We use your information to ensure that our services are working as intended, such as tracking downtime or troubleshooting issues with specific features. We also use your information to make improvements to our services - for example, understanding how you use a particular feature to improve it for you.</li>
                    <li><strong>Develop new services</strong><br /> We use the information we collect in existing Bermani services to help us develop new ones.</li>
                    <li><strong>Measure performance</strong><br /> Bermani is committed to delivering a resilient and efficient service. We use data for analytics and measurement to understand how Bermani is used. For example, we analyse data about your use of certain features in order to develop improvements to that feature.</li>
                    <li><strong>Communicate with you</strong><br /> We use information we collect, such as your email address, to interact with you directly. We may send you notifications relevant to the Bermani platform.</li>
                </ul>
                <p><strong>Sharing your information</strong></p>
                <p>We do not share you personal information with companies, organisations or individuals outside of Bermani except in the following cases:</p>
                <ul>
                    <li><strong>With your consent</strong><br /> We&rsquo;ll share Personal Data outside of Bermani when we have your consent.</li>
                    <li><strong>With Organisation administrators</strong><br /> Your organisation administrators will have access to your Bermani account. They may be able to:</li>
                    <ul>
                        <li>Access and retain information stored in your account such as your email</li>
                        <li>View data and statistics regarding your account</li>
                        <li>Suspend or terminate your Bermani account access</li>
                        <li>Receive your Bermani account information in order to satisfy applicable law, regulation, legal process or enforceable governmental request.</li>
                        <li>Change your Bermani account password</li>
                        <li>Restrict your ability to delete or edit your information or your privacy settings</li>
                    </ul>
                    <li><strong>For external processing</strong><br /> We share Personal Data with a limited number of our service providers. We have service providers that provide services on our behalf, such as identity verification services, website hosting, data analysis, information technology, machine learning, artificial intelligence and related infrastructure, customer support, email delivery. These service providers may need to access Personal Data to perform their services. We authorise such service providers to use or disclose the Personal Data only as necessary to perform services on our behalf or comply with legal requirements. We require such service providers to contractually commit to protect the security and confidentiality of Personal Data they process on our behalf. Our service providers are predominantly located in the European Union and the United States of America. Where possible we prioritise data hosting and service providers located in the United Kingdom.</li>
                    <li><strong>For legal reasons</strong><br /> We will share personal information outside of Bermani if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:</li>
                    <ul>
                        <li>Comply with applicable law</li>
                        <li>Enforce our contractual rights</li>
                        <li>Protect the rights, privacy, safety and property of Bermani, you or others</li>
                        <li>Respond to requests from courts, law enforcement agencies, regulatory agencies and other public and government authorities, which may include authorities outside of your country of residence.</li>
                    </ul>
                </ul>
                <p>If Bermani is involved in a merger, acquisition or sale of assets, we&rsquo;ll continue to ensure the confidentiality of your personal information and give affected users notice before personal information is transferred or becomes subject to a different privacy policy.</p>
                <p><strong>Security and Retention</strong></p>
                <p>We make reasonable efforts to ensure a level of security appropriate to the risk associated with the processing of Personal Data. We maintain organisational, technical and administrative measures designed to protect Personal Data within our organisation against unauthorised access, destruction, loss, alteration or misuse.</p>
                <p>Your Personal Data is only accessible to a limited number of Bermani personnel who need access to the information to perform their duties. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</p>
                <p>Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account has been compromised), please contact us immediately at&nbsp;<a style={{ color: '#FF0000' }} href="mailto:support@bermani.co.uk">support@bermani.co.uk</a> .</p>
                <p>If you are a Bermani User, we retain your Personal Data as long as we are providing the Services to you. We retain Personal Data after we cease providing Services to you, even if you close your Bermani account, to the extent necessary to comply with our legal and regulatory obligations, and for the purpose of fraud monitoring, detection and prevention. Where we retain data, we do so in accordance with any limitation periods and records retention obligations that are imposed by applicable law.</p>
                <p>We review our information collection, storage and processing practices, including physical security measures, to prevent unauthorised access to our systems.</p>
                <p><strong>Your rights</strong></p>
                <p>You have a choice regarding our use and disclosure of your Personal Data:</p>
                <p><strong>OPTING OUT OF RECEIVING ELECTRONIC COMMUNICATIONS FROM US</strong></p>
                <p>If you no longer want to receive marketing-related emails from us, you may opt-out via the unsubscribe link included in such emails or email <a style={{ color: '#FF0000' }} href="mailto:support@bermani.co.uk">support@bermani.co.uk</a> . We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out of receiving marketing-related emails from us, we may still send you important administrative messages that are required to provide you with our Services.</p>
                <p><strong>HOW YOU CAN REVIEW, CORRECT OR UPDATE YOUR PERSONAL DATA</strong></p>
                <p>If You would like to review, correct, or update Personal Data that You have previously disclosed to us, You may do so by signing in to your Bermani account or by contacting us.</p>
                <p>You undertake to notify your Employees of this privacy policy and of their rights under the Data Protection Law. We will provide you with reasonable assistance to enable you to comply and respond to a request, query or complaint from an Employee in relation to their Personal Data.</p>
                <p><strong>YOUR DATA PROTECTION RIGHTS</strong></p>
                <p>Depending on your location and subject to applicable law, you may have the following rights with regard to the Personal Data we control about you:</p>
                <ul>
                    <li>The right to request confirmation of whether Bermani processes Personal Data relating to you, and if so, to request a copy of that Personal Data;</li>
                    <li>The right to request that Bermani rectifies or updates your Personal Data that is inaccurate, incomplete or outdated;</li>
                    <li>The right to request that Bermani erase your Personal Data in certain circumstances provided by law;</li>
                    <li>The right to request that Bermani restrict the use of your Personal Data in certain circumstances, such as while Bermani considers another request that you have submitted (including a request that Bermani make an update to your Personal Data); and</li>
                    <li>The right to request that we export to another company, where technically feasible, your Personal Data that we hold in order to provide Services to you.</li>
                </ul>
                <p>In some cases, we retain data for limited periods when it needs to be kept for legitimate business or legal purposes.</p>
                <p>We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you delete something and when copies are deleted from our active and backup systems.</p>
                <p><strong>Process for exercising data protection rights</strong></p>
                <p>In order to exercise your data protection rights, you may contact Bermani as described in the Contact Us section below. We take each request seriously. We will comply with your request to the extent required by applicable law. We will not be able to respond to a request if we no longer hold your Personal Data. If you feel that you have not received a satisfactory response from us, you may consult with the data protection authority in your country.</p>
                <p>For your protection, we may need to verify your identity before responding to your request, such as verifying that the email address from which you send the request matches your email address that we have on file. If we no longer need to process Personal Data about you in order to provide our Services or our Sites, we will not maintain, acquire or process additional information in order to identify you for the purpose of responding to your request.</p>
                <p>If you are a Employee of a Bermani Organisation, please direct your requests directly to the Organisation. For example, if you are employed or were previously employed by an Organisation using Bermani as a clinical governance platform, and you have a request that is related to the information that you provided, then you should address your request directly to the Organisation.</p>
                <p><strong>Use by Minors</strong></p>
                <p>The Services are not directed to individuals under the age of sixteen (16), and we request that they not provide Personal Data through the Services.</p>
                <p><strong>Updates to this policy</strong></p>
                <p>We update this policy from time to time. We will always indicate the date when the latest changes were published. If changes are significant, we&rsquo;ll provide a more prominent notice by email.</p>
                <p><strong>Links to other websites</strong></p>
                <p>We may at times provide links on our Website to third party websites, including without limitation those owned or managed by our partner networks, affiliates or advertisers. These websites have separate privacy policies, and we therefore cannot accept any responsibility for the content. As such, choosing to follow these links is a choice you make at your own risk, and we advise that you check these websites' individual privacy policies before submitting any personal data.</p>
                <p><strong>Contact Us</strong></p>
                <p>If you have questions, you can contact Bermani and our Data Protection Officer at&nbsp;<a style={{ color: '#FF0000' }} href="mailto:haider@bermani.co.uk">haider@bermani.co.uk</a>. And you can contact your local data protection authority (in the UK, this is the Information Commissioner&rsquo;s Officer) if you have concerns regarding your rights under local law.</p>
                <p>&nbsp;</p>


            </div>
        );
    }
}
