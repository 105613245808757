import React, {  useEffect } from 'react';
import {
    Radar, RadarChart, PolarGrid, Legend,
    PolarAngleAxis, ResponsiveContainer
    
} from 'recharts';

export default function AccelerateChart(props) {

    const data = [
        {
            subject: 'Project Registration', A: 100, B: 25, fullMark: 100,
        },
        {
            subject: 'Project Governance', A: 100, B: 15, fullMark: 100,
        },
        {
            subject: 'Identify Standards', A: 100, B: 25, fullMark: 100,
        },
        {
            subject: 'Design DCT', A: 100, B: 15, fullMark: 100,
        },
        {
            subject: 'Collect Data', A: 100, B: 50, fullMark: 100,
        },
        {
            subject: 'Analyse Data', A: 100, B: 15, fullMark: 100,
        },
        {
            subject: 'Write Report', A: 100, B: 25, fullMark: 100,
        },
        {
            subject: 'Quality Improvement', A: 25, B: 90, fullMark: 100,
        },
        {
            subject: 'Re-audit', A: 100, B: 25, fullMark: 100,
        },

    ];


    useEffect(() => {



    }, [props.isMobile]);



    return (
        
 
            <ResponsiveContainer>
            <RadarChart
                data={data}
                outerRadius={props.isMobile ? 40 : '65%'}

            >


                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <Radar name="Time spent using a traditional Clinical Audit process" dataKey="A" stroke="#FFC83D" fill="#FFC83D" fillOpacity={0.6} />
                    <Radar name="Relative time spent using bermani" dataKey="B" stroke="#00B1EB" fill="#00B1EB" fillOpacity={0.6} />
                    <Legend />
                </RadarChart>
            </ResponsiveContainer>
       
        
    );
}

//
//<PolarRadiusAxis angle={90} domain={[0, 100]} />