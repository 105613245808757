import React, { Component } from 'react';
import { Map } from './GoogleMap';

export class Contact extends Component {
    static displayName = Contact.name;


    render() {
        return (
            <div id="Contact" className="container">


                <h1>Let's Connect!</h1>
                <br />
                <p> If you'd like to learn more about bermani and our service offering or if you have any press-related queries
                    please get in touch via email.
                </p>

                <a href="mailto:hello@bermani.co.uk" style={{ color: '#1d4477' }}>
                <p><span className="glyphicon glyphicon-envelope" ></span>
                    <span>            </span>
                    hello@bermani.co.uk </p>

                </a>

                {/*
                <p style={{ color: '#1d4477' }}>
                
                    <span className="glyphicon glyphicon-phone-alt" ></span>
                    <span>            </span>+44 796 108 9292
                </p>


                <br />
                <div className="container-flex">
                    <div className="row">


                        <div className="col-xl-6 col-lg-6  col-md-6">



                            <div id="addresscard" className="card" >
                                <div className="card-img-top" style={{ height: '200px', width: '100%' }}>
                                < Map  />
                                </div>
                                <div className="card-body">
                                    <div className="card-text">
                                        <p> <b>Bermani Limited </b> </p>
                                        <p> Alpha Works, Alpha Tower </p>
                                        <p> Suffolk Street Queensway, Birmingham</p>
                                        <p> West Midlands</p>
                                        <p> B1 1TT</p>
                                    </div>
                                </div>


                            </div>




                        </div>

                    </div>
                </div>

                */}


            </div>
        );
    }
}
